import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./Nav.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import barbas from "../../assets/barbas.jpeg";

export const Nav = () => {
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef(null);
  const navigate = useNavigate();

  const toggleMenu = () => {
    setIsOpen((prevIsOpen) => !prevIsOpen);
  };

  const closeMenu = () => {
    setIsOpen(false);
  };

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      closeMenu();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 769) {
        closeMenu();
      }
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <nav className="nav-container">
      <div className="menu-icon" onClick={toggleMenu}>
        <FontAwesomeIcon
          icon={faBars}
          style={{ fontSize: "24px", cursor: "pointer" }}
        />
      </div>
      <div className="logo-container">
        <Link to="/" onClick={closeMenu}>
          <img src={barbas} alt="logo" />
        </Link>
      </div>
      <div className="menu-icons">
        {/* Commenting out the cart icon */}
        {/* <FontAwesomeIcon
          icon={faShoppingCart}
          style={{ fontSize: "24px", cursor: "pointer" }}
          onClick={() => {
            closeMenu();
            navigate("/cart");
          }}
        /> */}
        <a
          href="https://booksy.com/en-us/990648_barbas-barbershop_barber-shop_14711_mesa?do=invite&_branch_match_id=1259188854095148311&utm_medium=merchant_customer_invite&_branch_referrer=H4sIAAAAAAAAA8soKSkottLXT07J0UvKz88urtRLzs%2FV9%2Fdyci13KfY2904CABweIFciAAAA"
          target="_blank"
          rel="noopener noreferrer"
          className="book-now-button"
        >
          Book Now
        </a>
      </div>
      <div className={`menu ${isOpen ? "open" : ""}`} ref={menuRef}>
        <Link to="/" onClick={closeMenu}>
          Home
        </Link>
        <Link to="/gallery" onClick={closeMenu}>
          Gallery
        </Link>
        <Link to="/about" onClick={closeMenu}>
          About
        </Link>
      </div>
    </nav>
  );
};
