import React, { Suspense, lazy } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import { Nav } from "../components/Nav/Nav.js";
import { Footer } from "../components/Foot/Footer.js";

// Lazy load components
const LandingPage = lazy(() => import("./LandingPage.js"));
const Products = lazy(() => import("./Products.js"));
const CloverPayment = lazy(() => import("./CloverPayment.js"));
const GalleryPage = lazy(() => import("./GalleryPage.js"));
const About = lazy(() => import("./About.js"));
const Cart = lazy(() => import("./Cart.js"));

export const Router = () => {
  return (
    <BrowserRouter basename="/">
      <Suspense fallback={<div>Loading...</div>}>
        <Nav />
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/products" element={<Products />} />
          <Route path="/checkout" element={<CloverPayment />} />
          <Route path="/gallery" element={<GalleryPage />} />
          <Route path="/about" element={<About />} />
          <Route path="/cart" element={<Cart />} />
        </Routes>
        <Footer />
      </Suspense>
    </BrowserRouter>
  );
};
